import { apiCall, apiCallBlob } from "./api";

export const fetchSurveys = () => {
  const date = new Date(Date.now());
  let offset = (-1 * date.getTimezoneOffset()) / 60;
  return apiCall("GET", `api/v1/survey/user_surveys?timezoneOffset=${offset}`);
};

export const fetchSurvey = (id) =>
  apiCall("GET", `api/v1/survey/${id}/with-substituted-messages`);

export const fetchCompetencyLevels = (id) =>
  apiCall("GET", `api/v1/Survey/${id}/score-settings`);

export const fetchParentSurveys = () =>
  apiCall("GET", "api/v1/survey/history-parent-surveys");

export const beginSurvey = (id) =>
  apiCall("POST", `api/v1/survey/${id}/start`, { surveyId: parseInt(id, 10) });

export const answerQuestion = (id, questionId, answerId) =>
  apiCall("POST", `api/v1/Survey/${id}/save_answer`, {
    surveyId: parseInt(id, 10),
    questionId,
    responseChoiceId: answerId,
  });

export const prevQuestion = (id, currentQuestionId) =>
  apiCall("GET", `api/v1/Survey/${id}/prev-question/${currentQuestionId}`);

export const downloadSurvey = (data) => {
  const {id, competencyImg, subcompetencyImg, competencySorting, subcompetencySorting} = data;

  let competencySortingValue = {};
  competencySortingValue[competencySorting.column || "NONE"] = competencySorting.direction 

  let subcompetencySortingValue = {};
  subcompetencySortingValue[subcompetencySorting.column || "NONE"] = subcompetencySorting.direction 


  return apiCallBlob("POST", `api/v1/survey/${id}/download_results`, {
    userCoreCompetenciesAndTargetsImage: `data:image/svg+xml;base64, ${competencyImg}`,
    userSubCompetenciesAndTargetsImage: `data:image/svg+xml;base64, ${subcompetencyImg}`,
    competencySorting: competencySortingValue,
    subCompetencySorting: subcompetencySortingValue,
  });
}

export const releaseResults = (id) =>
  apiCall("PUT", `api/v1/survey/${id}/release_results`);

export const exportPeople = (surveyId, status) => {
  return apiCallBlob(
    "GET",
    `api/v1/File/survey-users-list/${surveyId}/${status}`,
  );
}

export const getCompanyClosedAssessmentsCall = (organizationId, pageNr, pageSz, keyword, sorting = null) => {
  const params = new URLSearchParams();
  params.append('pageSize', pageSz || "100000");
  params.append('pageNumber',pageNr || "1");
  params.append('keyword', keyword || '')
  if(sorting) {
    const key = Object.keys(sorting)
    params.append('sortingKey', key[0]);
    params.append('sortingValue', sorting[key]);
  }

  return apiCall("GET", `api/v1/survey/closed-surveys-for-organization/${organizationId}?${params.toString()}`);
} 