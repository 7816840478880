import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '../views/Home';

Vue.use(VueRouter);

const adminLinks = [
  {
    icon: 'home',
    name: 'Dashboard',
    route: '/',
  },
  {
    icon: 'assessment',
    name: 'Assessments',
    route: '/assessments',
  },
  {
    icon: 'results',
    name: 'Results',
    route: '/company-results/:id',
  },
  { icon: 'people', name: 'People', route: '/people' },
  {
    icon: 'team',
    name: 'Teams',
    route: '/admin-teams',
  },
];

const sellerLinks = [
  {
    icon: 'company',
    name: 'Companies',
    route: '/',
  },
];

const setupAssessmentLinks = [
  {
    name: '1. General Information',
    route: '/assessments/general-information',
  },
  { name: '2. Participants', route: '/assessments/participants' },
  { name: '3. Job Roles', route: '/assessments/job-roles' },
  { name: '4. Targets', route: '/assessments/targets' },

  { name: '5. Settings', route: '/assessments/settings' },
  { name: '6. Communication', route: '/assessments/communication' },
  { name: '7. Summary', route: '/assessments/summary' },
];

const routes = [
  {
    path: '/teams/:id',
    name: 'TeamDetails',
    meta: {
      hasSidenav: true,
      sidenavFor: ['manager'],
      permittedRoles: ['manager'],
      sidenav: {
        isDeep: true,
        deepName: 'Teams Management',
        deepPath: '/teams',
      },
    },
    component: () => import('../views/Team/views/TeamDetails'),
  },
  {
    path: '/teams',
    name: 'TeamManagement',
    meta: {
      hasSidenav: true,
      sidenavFor: ['manager'],
      permittedRoles: ['manager'],
      sidenav: {
        links: [
          {
            icon: 'home',
            name: 'Dashboard',
            route: '/',
          },
          {
            icon: 'team',
            name: 'Team Management',
            route: '/teams',
          },
          {
            icon: 'results',
            name: 'Team Results',
            route: '/team-results/:id/:teamId',
          },
        ],
      },
    },
    component: () => import('../views/Team/views/TeamManagement'),
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    meta: {
      hasSidenav: true,
      sidenavFor: ['employee', 'manager', 'admin', 'seller'],
      permittedRoles: ['employee', 'manager', 'admin', 'seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
      },
    },
    component: () => import('../views/Account/views'),
  },
  {
    path: '/teams/new',
    name: 'NewTeam',
    meta: {
      hasSidenav: true,
      sidenavFor: ['admin'],
      permittedRoles: ['admin'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Team Management',
        links: [
          {
            name: 'General Information',
            route: '/teams/new',
          },
          {
            name: 'Members',
            route: '/teams/new?members',
          },
        ],
      },
    },
    component: () => import('../views/Team/views/NewTeam'),
  },
  {
    path: '*',
    name: '404',
    meta: { noHeader: true, isPublic: true, noContainer: true },
    component: () => import('../views/404'),
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: { noHeader: true, isPublic: true, noContainer: true },
    component: () => import('../views/Auth/views/Logout'),
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    meta: {
      noHeader: true,
      isPublic: true,
      noContainer: true,
    },
    component: () => import('../views/Onboarding'),
  },
  {
    path: '/login/',
    name: 'Login',
    meta: {
      noHeader: true,
      isPublic: true,
    },
    component: () => import('../views/Auth/views/'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPass',
    meta: {
      noHeader: true,
      isPublic: true,
    },
    component: () => import('../views/Auth/views/ForgotPassword'),
  },
  {
    path: '/reset-password',
    name: 'ResetPass',
    meta: {
      noHeader: true,
      isPublic: true,
    },
    component: () => import('../views/Auth/views/ResetPassword'),
  },
  {
    path: '/assessments',
    name: 'Assessments',
    component: () => import('../views/AdminAssessments/views'),
    meta: {
      hasSidenav: true,
      sidenavFor: ['admin'],
      permittedRoles: ['admin'],
      sidenav: {
        links: adminLinks,
      },
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      hasSidenav: true,
      sidenavFor: ['seller', 'admin', 'manager', 'admin'],
      permittedRoles: ['seller', 'employee', 'admin', 'manager'],
      sidenav: {
        seller: {
          links: sellerLinks,
        },
        admin: {
          links: adminLinks,
        },
        manager: {
          links: [
            {
              icon: 'home',
              name: 'Dashboard',
              route: '/',
            },
            {
              icon: 'team',
              name: 'Team Management',
              route: '/teams',
            },
            {
              icon: 'results',
              name: 'Team Results',
              route: '/team-results/:id/:teamId',
            },
          ],
        },
      },
    },
  },
  {
    path: '/assessment/:id',
    name: 'AssessmentView',
    meta: {
      permittedRoles: ['employee', 'admin'],
      assessmentHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "AssessmentView" */ '../views/Assessment/views/'
      ),
  },
  {
    path: '/results/history',
    name: 'ResultsHistory',
    meta: {
      hasSidenav: true,
      sidenavFor: ['employee'],
      permittedRoles: ['employee'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Results',
        links: [
          {
            name: 'History',
            route: '/results/history',
          },
          {
            name: 'By Assessment',
            route: '/result/:id',
          },
        ],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "AssessmentHistory" */ '../views/Results/views/History'
      ),
  },
  {
    path: '/result/:id',
    name: 'AssessmentResult',
    meta: {
      hasSidenav: true,
      permittedRoles: ['employee'],
      sidenavFor: ['employee'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Results',
        links: [
          {
            name: 'History',
            route: '/results/history',
          },
          {
            name: 'By Assessment',
            route: '/result/:id',
          },
        ],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "AssessmentResult" */ '../views/Results/views/AssessmentResult'
      ),
  },
  {
    path: '/assessment/:id/survey',
    name: 'AssessmentSurvey',
    meta: {
      permittedRoles: ['employee'],
      assessmentHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "AssessmentQuiz" */ '../views/Assessment/views/Survey.vue'
      ),
  },
  {
    path: '/assessment/:id/survey/end',
    name: 'AssessmentSurveyFinish',
    meta: {
      permittedRoles: ['employee'],
      assessmentHeader: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "AssessmentQuizFinish" */ '../views/Assessment/views/Finish.vue'
      ),
  },
  {
    path: '/team-results/history',
    name: 'TeamResultsHistory',
    meta: {
      hasSidenav: true,
      permittedRoles: ['manager'],
      sidenavFor: ['employee', 'manager'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Results',
        links: [
          {
            name: 'By Assessment',
            route: '/team-results/:id/:teamId',
          },
        ],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "AssassmentCompanyHistory" */ '../views/TeamResults/views/History'
      ),
  },
  {
    path: '/team-results/:id/:teamId',
    name: 'TeamAssessmentResult',
    meta: {
      hasSidenav: true,
      permittedRoles: ['manager'],
      sidenavFor: ['manager'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Team Results',
        links: [
          {
            name: 'By Assessment',
            route: '/team-results/:id/:teamId',
          },
        ],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "AssessmentTeamResults" */ '../views/TeamResults/views/TeamAssessmentResult.vue'
      ),
  },
  {
    path: '/team-results/empty',
    name: 'TeamResultsEmpty',
    meta: {
      hasSidenav: true,
      permittedRoles: ['manager'],
      sidenavFor: ['manager'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Team Results',
        links: [
          {
            name: 'By Assessment',
            route: '/team-results/empty',
          },
        ],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "AssessmentTeamResultsEmpty" */ '../views/TeamResults/views/TeamResultsEmpty.vue'
      ),
  },
  {
    path: '/company-results/:id',
    name: 'CompanyAssessmentResult',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Company Results',
        links: [
          {
            name: 'By Assessment',
            route: '/company-results/:id',
          },
        ],
      },
    },
    component: () =>
      import('../views/CompanyResults/views/CompanyAssessmentResult.vue'),
  },
  {
    path: '/company-results-empty',
    name: 'CompanyAssessmentResultEmpty',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        isDeep: true,
        deepName: 'Dashboard',
        deepPath: '/',
        title: 'Company Results',
        links: [
          {
            name: 'By Assessment',
            route: '/company-results-empty',
          },
        ],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "AssassmentCompanyResultsEmpty" */ '../views/CompanyResults/views/CompanyResultsEmpty.vue'
      ),
  },
  {
    path: '/assessments/general-information',
    name: 'GeneralInformation',
    component: () =>
      import('../views/AdminAssessments/views/GeneralInformation.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/assessments/participants',
    name: 'Participants',
    component: () => import('../views/AdminAssessments/views/Participants.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/assessments/job-roles',
    name: 'JobRoles',
    component: () => import('../views/AdminAssessments/views/JobRoles.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/assessments/targets',
    name: 'Targets',
    component: () => import('../views/AdminAssessments/views/Targets.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/assessments/groups',
    name: 'Custom Slicers (Optional)',
    component: () => import('../views/AdminAssessments/views/Groups.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/assessments/settings',
    name: 'Settings',
    component: () => import('../views/AdminAssessments/views/Settings.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/assessments/communication',
    name: 'Communication',
    component: () =>
      import('../views/AdminAssessments/views/Communication.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/assessments/communication/activity',
    name: 'Communication Activity',
    component: () =>
      import('../views/AdminAssessments/views/CommunicationActivity.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Communication',
        deepPath: '/assessments/communication',
        links: [],
      },
    },
  },
  {
    path: '/assessments/summary',
    name: 'Summary',
    component: () => import('../views/AdminAssessments/views/Summary.vue'),
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin', 'seller'],
      sidenavFor: ['admin', 'seller'],
      newAssessmentFlow: true,
      sidenav: {
        isDeep: true,
        deepName: 'Assessments',
        deepPath: '/assessments',
        secondaryDeepPath: '/company/:companyId/assessments',
        links: setupAssessmentLinks,
      },
    },
  },
  {
    path: '/people',
    name: 'PeopleView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        links: adminLinks,
      },
    },
    component: () => import('../views/People/views/PeopleView.vue'),
  },
  {
    path: '/people/invite',
    name: 'PeopleInvite',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        isDeep: true,
        deepName: 'People',
        deepPath: '/people',
      },
    },
    component: () => import('../views/People/views/PeopleInvite.vue'),
  },
  {
    path: '/people/invite/send-emails',
    name: 'PeopleInviteEmails',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        isDeep: true,
        deepName: 'People',
        deepPath: '/people',
      },
    },
    component: () => import('../views/People/views/PeopleInviteEmails.vue'),
  },
  {
    path: '/people/:userId',
    name: 'PeopleUserView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        isDeep: true,
        deepName: 'People',
        deepPath: '/people',
      },
    },
    component: () => import('../views/People/views/PeopleUserView.vue'),
  },
  {
    path: '/admin-teams',
    name: 'AdminTeamsView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        links: adminLinks,
      },
    },
    component: () => import('../views/AdminTeams/views/index.vue'),
  },
  {
    path: '/admin-teams/:id',
    name: 'AdminTeamsViewId',
    meta: {
      hasSidenav: true,
      permittedRoles: ['admin'],
      sidenavFor: ['admin'],
      sidenav: {
        isDeep: true,
        deepName: 'Teams',
        deepPath: '/admin-teams',
      },
    },
    component: () => import('../views/AdminTeams/views/TeamDetailsView.vue'),
  },
  {
    path: '/company/:companyId/overview',
    name: 'CompanyDetailsView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Companies',
        deepPath: '/',
        links: [
          {
            name: 'Overview',
            route: '/company/:companyId/overview',
          },
          {
            name: 'Assessments',
            route: '/company/:companyId/assessments',
          },
          {
            name: 'People',
            route: '/company/:companyId/users',
          },
          {
            name: 'Subscription and Invites',
            route: '/company/:companyId/invites',
          },
        ],
      },
    },
    component: () => import('../views/Companies/views/CompanyDetailsView.vue'),
  },
  {
    path: '/company/:companyId/overview/company-details',
    name: 'CompanyEditView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Companies',
        deepPath: '/',
        links: [
          {
            name: 'Overview',
            route: '/company/:companyId/overview',
          },
          {
            name: 'Assessments',
            route: '/company/:companyId/assessments',
          },
          {
            name: 'People',
            route: '/company/:companyId/users',
          },
          {
            name: 'Subscription and Invites',
            route: '/company/:companyId/invites',
          },
        ],
      },
    },
    component: () => import('../views/Companies/views/CompanyEditView.vue'),
  },
  {
    path: '/company/:companyId/users',
    name: 'CompanyUsersView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Companies',
        deepPath: '/',
        links: [
          {
            name: 'Overview',
            route: '/company/:companyId/overview',
          },
          {
            name: 'Assessments',
            route: '/company/:companyId/assessments',
          },
          {
            name: 'People',
            route: '/company/:companyId/users',
          },
          {
            name: 'Subscription and Invites',
            route: '/company/:companyId/invites',
          },
        ],
      },
    },
    component: () => import('../views/Companies/views/CompanyUsersView.vue'),
  },
  {
    path: '/company/:companyId/users/invite',
    name: 'CompanyUsersInvite',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'People',
        deepPath: '/company/:companyId/users',
      },
    },
    component: () => import('../views/Companies/views/CompanyUsersInvite.vue'),
  },
  {
    path: '/company/:companyId/subscription-details',
    name: 'SubscriptionDetails',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Subscription and Invites',
        deepPath: '/company/:companyId/invites',
      },
    },
    component: () => import('../views/Companies/views/SubscriptionDetails.vue'),
  },
  {
    path: '/company/:companyId/schedule-subscription-renewal',
    name: 'SubscriptionRenewal',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Subscription and Invites',
        deepPath: '/company/:companyId/invites',
      },
    },
    component: () => import('../views/Companies/views/SubscriptionRenewal.vue'),
  },
  {
    path: '/company/:companyId/subscription-history',
    name: 'SubscriptionHistory',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Subscription and Invites',
        deepPath: '/company/:companyId/invites',
      },
    },
    component: () => import('../views/Companies/views/SubscriptionsHistory.vue'),
  },
  {
    path: '/company/:companyId/users/invite/send-emails',
    name: 'CompanyUsersInviteEmails',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'People',
        deepPath: '/company/:companyId/users',
      },
    },
    component: () =>
      import('../views/Companies/views/CompanyUsersInviteEmails.vue'),
  },
  {
    path: '/company/:companyId/admin/:userId',
    name: 'CompanyEditAdmin',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Overview',
        deepPath: '/company/:companyId/overview',
      },
    },
    component: () => import('../views/Companies/views/CompanyEditAdmin.vue'),
  },
  {
    path: '/company/:companyId/users/:userId',
    name: 'CompanyIndividualUserView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'People',
        deepPath: '/company/:companyId/users',
      },
    },
    component: () =>
      import('../views/Companies/views/CompanyIndividualUserView.vue'),
  },
  {
    path: '/company/:companyId/assessments',
    name: 'CompanyAssessmentsView',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Companies',
        deepPath: '/',
        links: [
          {
            name: 'Overview',
            route: '/company/:companyId/overview',
          },
          {
            name: 'Assessments',
            route: '/company/:companyId/assessments',
          },
          {
            name: 'People',
            route: '/company/:companyId/users',
          },
          {
            name: 'Subscription and Invites',
            route: '/company/:companyId/invites',
          },
        ],
      },
    },
    component: () =>
      import('../views/Companies/views/CompanyAssessmentsView.vue'),
  },
  {
    path: '/company/:companyId/assessments/:assessmentId/communication',
    name: 'CompanyManageCommunicationStep1',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Assessments',
        deepPath: '/company/:companyId/assessments',
      },
    },
    component: () =>
      import('../views/Companies/views/CompanyManageCommunicationStep1.vue'),
  },
  {
    path: '/company/:companyId/assessments/:assessmentId/send-emails',
    name: 'CompanyManageCommunicationStep2',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Assessments',
        deepPath: '/company/:companyId/assessments',
      },
    },
    component: () =>
      import('../views/Companies/views/CompanyManageCommunicationStep2.vue'),
  },
  {
    path: '/company/:companyId/invites',
    name: 'CompaniesInvitesSummary',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Companies',
        deepPath: '/',
        links: [
          {
            name: 'Overview',
            route: '/company/:companyId/overview',
          },
          {
            name: 'Assessments',
            route: '/company/:companyId/assessments',
          },
          {
            name: 'People',
            route: '/company/:companyId/users',
          },
          {
            name: 'Subscription and Invites',
            route: '/company/:companyId/invites',
          },
        ],
      },
    },
    component: () => import('../views/Companies/views/CompanyInvitesView.vue'),
  },
  {
    path: '/company/:companyId/old-assessment/:assessmentId',
    name: 'OldAssessmentConfigurationSummary',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        title: '',
        deepName: 'Company Assessments',
        deepPath: '/company/:companyId/assessments',
      },
    },
    component: () => import('../views/Companies/views/OldAssessmentConfigurationSummary.vue'),
  },
  {
    path: '/my-platform',
    name: 'SellerPlatform',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        links: [
          { icon: 'team', name: 'Admins', route: '/my-platform' },
          {
            icon: 'job',
            name: 'Job Roles',
            route: '/job-roles',
          },
          {
            icon: "competencies",
            name: "Competencies",
            route: "/competencies",
          },
          {
            icon: 'products',
            name: 'Products',
            route: '/products',
          },
          { icon: 'invites', name: 'Subscription and Invites', route: '/invites' },
          { icon: 'assessment', name: 'Open Assessmnets', route: '/open-assessmnets' },
        ],
      },
    },
    component: () => import('../views/Companies/views/MyPlatform.vue'),
  },
  {
    path: '/my-platform/:userId',
    name: 'ManageUser',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Admins',
        deepPath: '/my-platform',
      },
    },
    component: () => import('../views/Companies/views/ManageUser.vue'),
  },
  {
    path: '/job-roles',
    name: 'Job Roles',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        links: [
          { icon: 'team', name: 'Admins', route: '/my-platform' },
          {
            icon: 'job',
            name: 'Job Roles',
            route: '/job-roles',
          },
          {
            icon: "competencies",
            name: "Competencies",
            route: "/competencies",
          },
          {
            icon: 'products',
            name: 'Products',
            route: '/products',
          },
          { icon: 'invites', name: 'Subscription and Invites', route: '/invites' },
          { icon: 'assessment', name: 'Open Assessmnets', route: '/open-assessmnets' },
        ],
      },
    },
    component: () => import('../views/Companies/views/JobRoleManagement.vue'),
  },
  {
    path: '/invites',
    name: 'Invites',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        links: [
          { icon: 'team', name: 'Admins', route: '/my-platform' },
          {
            icon: 'job',
            name: 'Job Roles',
            route: '/job-roles',
          },
          {
            icon: "competencies",
            name: "Competencies",
            route: "/competencies",
          },
          {
            icon: 'products',
            name: 'Products',
            route: '/products',
          },
          { icon: 'invites', name: 'Subscription and Invites', route: '/invites' },
          { icon: 'assessment', name: 'Open Assessmnets', route: '/open-assessmnets' },
        ],
      },
    },
    component: () => import('../views/Companies/views/InvitesManagement.vue'),
  },
  {
    path: '/competencies',
    name: 'Competencies',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        links: [
          { icon: 'team', name: 'Admins', route: '/my-platform' },
          {
            icon: 'job',
            name: 'Job Roles',
            route: '/job-roles',
          },
          {
            icon: "competencies",
            name: "Competencies",
            route: "/competencies",
          },
          {
            icon: 'products',
            name: 'Products',
            route: '/products',
          },
          { icon: 'invites', name: 'Subscription and Invites', route: '/invites' },
          { icon: 'assessment', name: 'Open Assessmnets', route: '/open-assessmnets' },
        ],
      },
    },
    component: () => import('../views/Companies/views/CompetenciesView.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        links: [
          { icon: 'team', name: 'Admins', route: '/my-platform' },
          {
            icon: 'job',
            name: 'Job Roles',
            route: '/job-roles',
          },
          {
            icon: "competencies",
            name: "Competencies",
            route: "/competencies",
          },
          {
            icon: 'products',
            name: 'Products',
            route: '/products',
          },
          { icon: 'invites', name: 'Subscription and Invites', route: '/invites' },
          { icon: 'assessment', name: 'Open Assessmnets', route: '/open-assessmnets' },
        ],
      },
    },
    component: () => import('../views/Products/views/ProductsView.vue'),
  },
  {
    path: '/open-assessmnets',
    name: 'Open Assessmnets',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        links: [
          { icon: 'team', name: 'Admins', route: '/my-platform' },
          {
            icon: 'job',
            name: 'Job Roles',
            route: '/job-roles',
          },
          {
            icon: "competencies",
            name: "Competencies",
            route: "/competencies",
          },
          {
            icon: 'products',
            name: 'Products',
            route: '/products',
          },
          { icon: 'invites', name: 'Subscription and Invites', route: '/invites' },
          { icon: 'assessment', name: 'Open Assessmnets', route: '/open-assessmnets' },
        ],
      },
    },
    component: () => import('../views/Companies/views/OpenAssessmentsManagement.vue'),
  },
  /* {
    path: "/competencies/manage",
    name: "New Competency",
    meta: {
      hasSidenav: true,
      permittedRoles: ["seller"],
      sidenavFor: ["seller"],
      sidenav: {
        isDeep: true,
        deepName: "Competencies",
        deepPath: "/competencies",
      },
    },
    component: () =>
      import("../views/Companies/views/CompetencyManagement.vue"),
  }, REMOVED COMPETENCIES FLOW, WILL ENABLE ONCE WE COME BACK TO IT*/

  {
    path: '/job-roles/management/general-information',
    name: 'Job Roles General Information',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Job Roles',
        deepPath: '/job-roles',
        title: 'New Job Role',
        links: [
          {
            name: '1. General Information',
            route: '/job-roles/management/general-information',
          },
          {
            name: '2. Benchmarks',
            route: '/job-roles/management/benchmarks',
          },
        ],
      },
    },
    component: () => import('../views/Companies/views/JobRoleView.vue'),
  },
  {
    path: '/job-roles/management/benchmarks',
    name: 'Job Roles Benchmarks',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Job Roles',
        deepPath: '/job-roles',
        title: 'New Job Role',
        links: [
          {
            name: '1. General Information',
            route: '/job-roles/management/general-information',
          },
          {
            name: '2. Benchmarks',
            route: '/job-roles/management/benchmarks',
          },
        ],
      },
    },
    component: () => import('../views/Companies/views/JobRoleTargets.vue'),
  },
  {
    path: '/products/:productId/details',
    name: 'ProductDetails',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product Details',
        deepPath: '/products/:productId/dashboard',
      },
    },
    component: () => import('../views/Products/views/ProductDetails.vue'),
  },
  {
    path: '/products/:productId/dashboard',
    name: 'ProductDashboard',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: () => store.getters['products/getRouteNameToRedirectTo'],
        deepPath: () => `/${store.getters['products/getRoutePathToRedirectTo']}`
      },
    },
    component: () => import('../views/Products/views/ProductDashboard.vue'),
  },
  {
    path: '/products/:productId/competencies',
    name: 'ProductCompetencies',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
      },
    },
    component: () => import('../views/Products/views/ProductCompetencies.vue'),
  },
  {
    path: '/products/:productId/new-competency',
    name: 'NewCompetency',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Competencies',
        deepPath: '/products/:productId/competencies',
      },
    },
    component: () => import('../views/Products/views/NewCompetency.vue'),
  },
  {
    path: '/products/:productId/edit-competency/:competencyId',
    name: 'EditCompetency',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product Details',
        deepPath: '/products/:productId/dashboard',
      },
    },
    component: () => import('../views/Products/views/NewCompetency.vue'),
  },
  {
    path: '/products/:productId/view-competency/:competencyId',
    name: 'ViewCompetency',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product Details',
        deepPath: '/products/:productId/dashboard',
      },
    },
    component: () => import('../views/Companies/views/CompetencyView.vue'),
  },
  {
    path: '/products/:productId/job-roles',
    name: 'ProductJobRoles',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
      },
    },
    component: () => import('../views/Products/views/ProductJobRoles.vue'),
  },
  {
    path: '/products/:productId/job-role/general-information',
    name: 'Job Roles General Information',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
        title: 'Job Role Details',
        links: [
          {
            name: '1. General Information',
            route: '/products/:productId/job-role/general-information',
          },
          {
            name: '2. Benchmarks',
            route: '/products/:productId/job-role/benchmarks',
          },
        ],
      },
    },
    component: () => import('../views/Products/views/JobRoleCreate.vue'),
  },
  {
    path: '/products/:productId/job-role/benchmarks',
    name: 'Job Roles Targets',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
        title: 'Job Role Details',
        links: [
          {
            name: '1. General Information',
            route: '/products/:productId/job-role/general-information',
          },
          {
            name: '2. Benchmarks',
            route: '/products/:productId/job-role/benchmarks',
          },
        ],
      },
    },
    component: () => import('../views/Products/views/JobRoleTargets.vue'),
  },
  {
    path: '/products/:productId/scores',
    name: 'ProductScores',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
        title: 'Survey Details',
        links: [
          {
            name: '1. Product Scores',
            route: '/products/:productId/scores',
          },
          {
            name: '2. Questions',
            route: '/products/:productId/questions',
          },
        ],
      },
    },
    component: () => import('../views/Products/views/ProductScores.vue'),
  },
  {
    path: '/products/:productId/questions',
    name: 'ProductQuestions',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
        title: 'Survey Details',
        links: [
          {
            name: '1. Product Scores',
            route: '/products/:productId/scores',
          },
          {
            name: '2. Questions',
            route: '/products/:productId/questions',
          },
        ],
      },
    },
    component: () => import('../views/Products/views/ProductQuestions.vue'),
  },
  {
    path: '/products/:productId/communication',
    name: 'ProductCommunication',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
      },
    },
    component: () => import('../views/Products/views/ProductCommunication.vue'),
  },
  {
    path: '/products/:productId/organizational-targets',
    name: 'OrganizationalTargets',
    meta: {
      hasSidenav: true,
      permittedRoles: ['seller'],
      sidenavFor: ['seller'],
      sidenav: {
        isDeep: true,
        deepName: 'Product details',
        deepPath: '/products/:productId/dashboard',
      },
    },
    component: () => import('../views/Products/views/OrganizationalTargets.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isPublic)) {
    next();
  } else if (!store.getters['auth/isLogged']) {
    next({
      path: '/login',
    });
  } else {
    if (
      !to.meta.permittedRoles.some(
        (record) => record === store.getters['home/getRole']
      )
    ) {
      next({
        path: '/login',
      });
    } else {
      next();
    }
  }
});

export default router;
