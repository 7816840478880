import { apiCall } from './api';

export const fetchIndustries = () => apiCall('GET', 'api/v1/Industry');

export const getCoreCompetencies = (payload) => apiCall('POST', 'api/v1/Competency/core_competencies', payload);

export const createCompany = (payload) =>
  apiCall('POST', 'api/v1/Organization', payload);

export const getCompanyById = (id) =>
  apiCall('GET', `api/v1/Organization/${id}`);

export const getUser = (id) =>
  apiCall('GET', `api/v1/User/get_user?userId=${id}`);

export const getCompanyAdmins = (id, payload) =>
  apiCall('POST', `api/v1/Organization/${id}/users`, payload);

export const getCompanyUsers = (id, payload) =>
  apiCall('POST', `api/v1/Organization/${id}/users`, payload);

export const addAdmin = (payload) =>
  apiCall('POST', `api/Account/invite`, payload);

export const updateCompanyAdmin = (id, payload) =>
  apiCall('POST', `api/v1/Organization/${id}/update_company_admin`, payload);

export const updateLicenses = (id, payload) =>
  apiCall('PUT', `api/v1/Organization/${id}/update_licences`, payload);

export const updateCompany = (id, payload) =>
  apiCall('PUT', `api/v1/Organization/${id}`, payload);

export const updateSeller = (payload) =>
  apiCall('PATCH', `api/v1/User/update_admin_info`, payload);

export const fetchCompanies = (payload) =>
  apiCall('POST', `api/v1/Organization/search`, payload);

export const getJobRole = (payload) =>
  apiCall('GET', `api/v1/JobRole/${payload}`);

export const createJobRole = (name, description, jobTitles) =>
  apiCall('POST', 'api/v1/JobRole/create', {
    name,
    description,
    jobTitles,
  });

export const updateJobRole = (id, name, description, jobTitles) =>
  apiCall('PUT', 'api/v1/JobRole/update', {
    id,
    name,
    description,
    jobTitles,
  });

export const updateJobRoleTargets = (id, targets) =>
  apiCall('PATCH', `api/v1/JobRole/${id}/update-targets`, { targets });

export const resendPlatformInvitation = (id) =>
  apiCall('POST', `api/Account/resend-invitation?id=${id}`);

export const changeUserStatus = (userId, isActive) =>
  apiCall('PATCH', `api/Account/${userId}/change-status`, { isActive });

export const sendEmailToSelectedParticipants = (
  surveyMessageId,
  surveyParticipantIds
) =>
  apiCall('POST', 'api/v1/Survey/send-email-to-selected', {
    surveyMessageId,
    surveyParticipantIds,
  });

export const getActiveAssessments = (organizationId) =>
  apiCall(
    'GET',
    `api/v1/Survey/${organizationId}/not-completed-surveys-by-organization`
  );

export const getPlatformActiveAssessments = () =>
    apiCall(
      'GET',
      `api/v1/Survey/platform-not-completed-surveys`
    );

export const getParticipantsForActiveAssessment = (
  organizationId,
  id,
  pageNr,
  pageSz
) =>
  apiCall(
    'POST',
    `api/v1/Survey/${id}/participants_for_seller?organizationId=${organizationId}`,
    {
      pageNumber: pageNr,
      pageSize: pageSz,
    }
  );

export const getEmailsForActiveAssessment = (id, organizationId) =>
  apiCall(
    'GET',
    `api/v1/Survey/${id}/get_survey_communication_for_seller?organizationId=${organizationId}`
  );

export const saveUsersAsSellerApi = (users, companyId) =>
  apiCall(
    'POST',
    `api/v1/User/import_for_seller?companyId=${companyId}`,
    users
  );

export const exportUsers = (organizationId, status) => {
  return apiCall(
    "GET",
    `api/v1/File/${organizationId}/users-csv/${status ?? -1}`,
  );
}